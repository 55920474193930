
import React from 'react';
import Modal from './../../../../components/Modal/Modal';
import closeIcon from '../../../../assets/images/Mrg_01.png'

const PromotionalModal = ({onClose,text, show}) => {
  let headerModal = <p>Updates and promotions - The Maze</p>;


    return (
        <Modal show={show}>
          <div className="modal">
          <div className="bg-overlay" onClick={onClose}></div>
          <div className="container-overlay-big">
              <div className="container-overlay">
                  <div className="container-overlay-inside">
                      <div className="header-overlay">{headerModal}</div>
                      <div className="text-overlay">
                          <div dangerouslySetInnerHTML={{__html: text?.split('\n').join('<br/>')}}></div>
                      </div>
                  </div>
              </div>
                      <div className="close-overlay-btn" onClick={onClose}>
                        <img src={closeIcon} alt="Close Modal"/>
                      </div>
          </div>
          </div>
        </Modal>
    );
  }

export default PromotionalModal;
