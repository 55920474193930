import React from 'react';
import PropTypes from 'prop-types';


const LoadingScreen = props => {
    return (
        <>

        </>
    );
};

LoadingScreen.propTypes = {

};

export default LoadingScreen;
